import React from 'react';
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import processingManager from "../../photolab/ProcessingManager";
import {generatePath} from "react-router";
import clientStorage from "../../utils/client-storage";
import {saveAs} from "file-saver";
import Processing from "../../photolab/Processing";
import * as api from "../../utils/api";
import {Alert, Button, Col, Container, Figure, Image, Modal, Row, Spinner, Stack} from "react-bootstrap";
import {transformToDownloadUrl} from "../../utils/creative";
import {getCreativesConfigs} from "../../photolab/config";
import Creative from "../../photolab/Creative";

export default class ResultPage extends React.Component {

  state = {
    file: null,
    files: [],
    isReady: false,
    updatedAt: 0,
    selectedCreative: null,
  };

  componentDidMount() {
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const processingId = this.props.match.params.id;
      api.sharedProcessingsGet(processingId)
        .then((data) => {
          const restoredProcessing = new Processing();
          restoredProcessing.fromObject(data);
          processingManager.start(restoredProcessing);

          const url = new URL(window.location.href);
          if (url.searchParams.get("regenerate") === "1") {
            this.regenerateCreatives();
          }

          this.setState({isReady: true});
        })
        .catch((err) => {
          console.error(err);
          this.props.history.replace(generatePath(routes.INDEX));
        })
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }

    document.body.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);

    document.body.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (e) => {
    if (!this.state.selectedCreative) {
      return;
    }

    const creatives = processingManager.processing.creatives
      .filter((c) => c.getExtra("file").url === this.state.file.url);

    const selectedCreativeIndex = creatives.findIndex((c) => c.id === this.state.selectedCreative.id);

    if (e.key === "ArrowLeft" && selectedCreativeIndex > 0) {
      this.setState({selectedCreative: creatives[selectedCreativeIndex - 1]});
    }

    if (e.key === "ArrowRight" && selectedCreativeIndex < (creatives.length - 1)) {
      this.setState({selectedCreative: creatives[selectedCreativeIndex + 1]});
    }

    if (e.key === "d" || e.key === "в") {
      this.startDownloadImageUrl(this.state.selectedCreative.result);
    }
  }

  handleProcessingChanged = () => {
    clientStorage.setLatestSelectedImages([]);

    const processing = processingManager.processing;
    const url = new URL(window.location.href);

    const selectedFile = (url.searchParams.has("photoIndex") && processing.files[url.searchParams.get("photoIndex")])
      || processing.getExtra(Processing.SELECTED_GROUP)
      || processing.files[0];

    const selectedCreative = url.searchParams.has("template")
      && processing.creatives.find((c) => {
        return c.templateId === url.searchParams.get("template")
        && c.getExtra("file").url === selectedFile.url;
      });

    this.context.hideLoader();

    this.setState({
      file: selectedFile,
      files: [...processing.files],
      selectedCreative: selectedCreative || null,
      updatedAt: Date.now(),
    });
  };

  handleBackButtonClick = () => {
    processingManager.clear();
    window.location.href = "/?r=" + Math.random();
  };

  startDownloadImageUrl = (imageUrl) => {
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
    saveAs(transformToDownloadUrl(imageUrl), fileName);
  };

  handleTabClick = (file) => {
    if (file.url === this.state.file.url) {
      return;
    }

    const processing = processingManager.processing;

    processing.setExtra(Processing.SELECTED_GROUP, file);
    processingManager.update();

    const url = new URL(window.location.href);
    const fileIndex = processing.files.findIndex((f) => f.url === file.url);

    if (fileIndex > -1) {
      url.searchParams.set("photoIndex", fileIndex);
    }

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });

    this.setState({file});
  }

  handleCreativeClick = (creative) => {
    this.setState({selectedCreative: creative});

    const processing = processingManager.processing;

    const url = new URL(window.location.href);
    url.searchParams.set("template", creative.templateId);

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });
  };

  handleCloseModal = () => {
    this.setState({selectedCreative: null});

    const processing = processingManager.processing;

    const url = new URL(window.location.href);
    url.searchParams.delete("template");

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });
  };

  handleRegenerateButtonClick = () => {
    if (!window.confirm("Sure?")) {
      return;
    }

    this.context.showLoader();

    const processing = processingManager.processing;
    const processingUrlWithRegenerate = generatePath(routes.RESULT, {id: processing.id}) + "?regenerate=1&r=" + Math.random();

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          window.location.replace(processingUrlWithRegenerate);
        } else {
          this.regenerateCreatives();
        }
      })
      .catch((err) => {
        window.location.replace(processingUrlWithRegenerate);
      });
  };

  regenerateCreatives = () => {
    const processing = processingManager.processing;

    processing.creatives.splice(0, processing.creatives.length);
    processing.files.forEach((file, index) => {
      getCreativesConfigs(index).forEach((creativeConfig) => {
        const creative = new Creative().configureByConfig(creativeConfig, index);
        creative.setAsSelected(true);
        creative.setExtra("file", file);
        processing.addCreative(creative);
      });
    });

    processingManager.update();

    const url = new URL(window.location.href);
    url.searchParams.delete("regenerate");

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search,
    });
  };

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    const creatives = processingManager.processing.creatives
      .filter((c) => c.getExtra("file").url === this.state.file.url);

    const creativesItems = creatives.slice();
    const creativeBoxStyles = {width: "300px", display: "flex", justifyContent: "center", alignItems: "center", height: "inherit"};

    return <React.Fragment>

      <Modal show={this.state.selectedCreative != null} centered size="md" onHide={this.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Template #{this.state.selectedCreative && this.state.selectedCreative.templateId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.selectedCreative && this.state.selectedCreative.isPending && <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>}
          {this.state.selectedCreative && this.state.selectedCreative.isProcessed && <Stack gap={3}>
            <Image src={this.state.selectedCreative && this.state.selectedCreative.result} rounded fluid />
            <Button onClick={() => this.startDownloadImageUrl(this.state.selectedCreative.result)}>Download</Button>
          </Stack>}
        </Modal.Body>
      </Modal>

      <Container fluid className="mt-3 mb-3">
        <Button onClick={this.handleBackButtonClick}>Back to photochooser</Button>
        &nbsp;
        <Button className="justify-content-end" variant="danger" onClick={this.handleRegenerateButtonClick}>Regenerate all</Button>

        <hr />

        <Row>
          {this.state.files.map((file) => <Col key={file.url} xs={1}>
            <Figure onClick={() => this.handleTabClick(file)}>
              <Figure.Image
                src={file.url}
                rounded
                className={this.state.file && this.state.file.url === file.url ? "border border-5 border-danger" : ""}
              />
            </Figure>
          </Col>)}
        </Row>

        <hr />

        <div className="d-flex flex-wrap">
          {creativesItems.map((creative) => <React.Fragment key={creative.id}>
            {creative.isPending && <div style={creativeBoxStyles} className="m-2">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>}
            {creative.isProcessed && <Figure onClick={() => this.handleCreativeClick(creative)}>
              <Figure.Image src={creative.result} rounded style={{width: "300px"}} className="m-2" />
            </Figure>}
            {creative.isFailed && <Alert className="m-2" variant="danger" style={creativeBoxStyles}>
              Template {creative.templateId}<br />
              Procesisng failed<br />
              Code: {creative.error && creative.error.code}<br />
              Message: {creative.error && creative.error.message}
            </Alert>}
          </React.Fragment>)}
        </div>

      </Container>
    </React.Fragment>;
  }
}

ResultPage.contextType = AppContext;
